'use client';

import routes from '@config/routes';
import { serverFetcher } from '@instance';
import { LeagueEvent } from '@interfaces/APITravel';
import type { PropertyDetails } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { v2Links } from '@utils/navigation/links';
import { PageLoader } from '@v2/components';
import { redirect, useParams } from 'next/navigation';
import useSWR from 'swr';
import {
  HotelOverview,
  RoomsList,
  RooomSelectionBottomHeader,
} from './components';

import { PACKAGE_STORAGE } from '@config/storageKeys';
import useEventLoader from '@hooks/use-event-loader';
import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';
import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { cn } from '@v2/utils';
import { useEffect, useState } from 'react';

type Props = {
  event: LeagueEvent;
  eventId: string;
};

export function ViewHotelView({ eventId }: Props) {
  const storage = useStorage();
  useScrollToTopOnMount();


  const hasHydrated = useBuildPackagesStore((state) => state._hasHydrated);
  const [currentPackageData, setCurrentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );

  const { startDate, endDate, roomConfigurations } = useBuildPackagesStore(
    (state) => ({
      startDate: state.hotelStartDate,
      endDate: state.hotelEndDate,
      roomConfigurations: state.roomConfigurations,
    })
  );


  const roomSelections = useSearchHotelStore((state) => state.roomSelections);
  const params = useParams<{ id: string }>();

  const isBundle =
    currentPackageData?.selected?.tickets;

  const { loading } = useEventLoader(eventId, true);

  const { data, isValidating } = useSWR<{
    error: [];
    propertyDetails: PropertyDetails;
  }>(
    hasHydrated ?  routes.propertyDetails : undefined,
    (url) =>
      serverFetcher(!params?.id ? null : url, {
        body: {
          id: params?.id,
          startDate: startDate,
          endDate: endDate,
          roomConfigurations,
          isBundle: isBundle,
        },
      }),
    { revalidateIfStale: true, revalidateOnFocus: false }

  );

  useEffect(() => {
    useCheckoutStepsStore.setState({ hotelSelected: false });
  }, [])


  if (!hasHydrated) return <PageLoader />;

  if (!params?.id) return redirect(v2Links.home);

  if (isValidating || loading) return <PageLoader />;









  return (
    <section className="buy-package-height relative px-4 pt-4 md:mx-auto md:max-w-[1400px] md:px-20 md:pt-12">

      <HotelOverview propertyDetails={data.propertyDetails} />
      <RoomsList propertyDetails={data.propertyDetails} />

      <div
        className={cn(
          'pb-30 md:pb-20',
          roomSelections.length > 0 && 'pb-60 md:pb-40'
        )}
      />

      <RooomSelectionBottomHeader propertyDetails={data.propertyDetails} />
    </section>
  );
}
