import {
  SELECTED_HOTEL_STORAGE,
  SELECTED_TICKET_STORAGE,
} from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { IFormValues } from '@interfaces/buildPackage';
import {
  Button,
  Check,
  Close,
  Divider,
  Minus,
  Plus,
  Ticket01,
  Typography,
} from '@v2/ui';
import { themeConfig } from '@v2/utils';
import { FormikProps } from 'formik';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { StepName } from '../BuildYourPackage';
import { PackageType } from '../BuyPackage';
import * as PackageCard from './PackageContainer';

interface BuyTicketsProps {
  onAction: () => void;
  formik: FormikProps<IFormValues>;
  setStep: (name: StepName, value: boolean) => void;
  loading: boolean;
  ticketEntries: number | undefined;
  toggleHotel: (val: boolean) => void;
}

export function BuyTickets({
  onAction,
  formik,
  setStep,
  loading,
  ticketEntries,
  toggleHotel,
}: BuyTicketsProps) {
  const { tickets } = formik.values;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const storage = useStorage();
  const searchParams = useSearchParams();
  const isTicketOnly =
    searchParams!.get('packageType')! === ('tickets' as PackageType);

  useEffect(() => {
    if (!loading && Boolean(ticketEntries)) return setIsCollapsed(true);
  }, [loading, ticketEntries]);
  const onMinus = () => {
    if (tickets <= 1) {
      return;
    }
    formik.setFieldValue('tickets', tickets - 1);
  };

  const onPlus = () => {
    formik.setFieldValue('tickets', tickets + 1);
  };

  const onCancel = () => {
    storage.removeItem(SELECTED_TICKET_STORAGE);
    formik.setFieldValue('tickets', 0);
    setStep('tickets', false);
    setIsCollapsed(false);
    setIsSkip(true);
    toggleHotel(true);
  };

  const onContinue = () => {
    setHasConfirmed(true);
    setIsCollapsed(false);

    if (isTicketOnly) {
      storage.removeItem(SELECTED_HOTEL_STORAGE);

      return formik.submitForm();
    }

    onAction();
  };

  const isTicketsConfirmed = tickets > 0 && !isCollapsed && hasConfirmed;
  const isTicketsSkip = isSkip && tickets === 0;
  const noTicketAvailable = Boolean(ticketEntries);
  return (
    <div>
      <PackageCard.Card
        open={isCollapsed}
        disabled={noTicketAvailable || loading}
      >
        <PackageCard.Header
          loading={loading}
          onClick={() => {
            if (loading) return;
            if (!isTicketOnly && !isCollapsed) toggleHotel(false);
            setIsCollapsed(!isCollapsed);
            if (isSkip) {
              formik.setFieldValue('tickets', 2);
              isSkip && setIsSkip(false);
            }
            hasConfirmed && setHasConfirmed(false);
          }}
          trigger={
            !isCollapsed &&
            (isTicketsConfirmed || isTicketsSkip) && (
              <Button variant="secondary" className="w-max">
                {isTicketsConfirmed && 'Edit'}
                {isTicketsSkip && 'Add tickets'}
              </Button>
            )
          }
        >
          {isTicketsSkip && <Close pathProps={{ stroke: '#FA2846' }} />}

          {isTicketsConfirmed && <Check pathProps={{ stroke: '#079455' }} />}

          {!isTicketsConfirmed && !isTicketsSkip && (
            <Ticket01
              pathProps={{
                stroke: themeConfig.theme.colors.gray[800],
              }}
            />
          )}
          <Typography size="txl" className="font-bold">
            Tickets
          </Typography>
        </PackageCard.Header>

        {isTicketsConfirmed && (
          <div className="flex flex-col gap-y-2">
            <Typography size="tsm" className="font-medium text-gray-500">
              Number of tickets
            </Typography>
            <Typography size="tmd" className="font-medium">
              {formik.values.tickets}
            </Typography>
          </div>
        )}

        <PackageCard.ContentCollapse>
          <Typography size="tmd" className="font-semibold">
            How many tickets do you need?
          </Typography>

          <div className="flex items-center justify-start gap-x-2">
            <Button
              size="sm"
              variant="secondary"
              onClick={onMinus}
              className="h-[36px] w-[36px]"
              type="button"
            >
              <Minus size="20" className="text-gray-800" />
            </Button>
            <Typography
              size="tmd"
              className="min-w-[37px] text-center font-medium"
            >
              {tickets}
            </Typography>
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={onPlus}
              className="h-[36px] w-[36px]"
            >
              <Plus size="20" className="text-gray-800" />
            </Button>
          </div>

          <div className="flex flex-col-reverse items-center justify-end gap-x-4 gap-y-3 md:flex-row">
            {!isTicketOnly && (
              <Button
                variant="secondary"
                onClick={onCancel}
                type="button"
                className="w-full border-none md:w-max md:border"
              >
                I don&apos;t need tickets
              </Button>
            )}

            <Button
              type="button"
              onClick={onContinue}
              disabled={tickets === 0}
              className="w-full md:w-max"
            >
              Continue
            </Button>
          </div>
        </PackageCard.ContentCollapse>
      </PackageCard.Card>

      <Divider />
    </div>
  );
}
