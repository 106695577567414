'use client';
import { TrackedEvents } from '@config/index';
import {
  CHECKOUT_START_DATETIME,
  MIN_PRICES,
  PACKAGE_STORAGE,
  SELECTED_HOTEL_STORAGE,
  SOCIAL_LOGIN_REDIRECT,
} from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';

import routes from '@config/routes';
import useStorage from '@hooks/useStorage';
import instance from '@instance';
import { IFormValues } from '@interfaces/buildPackage';
import { PropertyDetails } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { useIsClient } from '@uidotdev/usehooks';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { links } from '@utils/navigation/links';
import { updateShoppingCart } from '@v2/action/shopping-cart/shopping-cart-action';
import { ShoppingCartStepEnum } from '@v2/action/shopping-cart/types';
import { Badge, Button, Check } from '@v2/ui';
import { cn } from '@v2/utils';
import { getCookie, setCookie } from 'cookies-next';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';

type Props = {
  propertyDetails: PropertyDetails;
};

export function RooomSelectionBottomHeader(props: Props) {
  const storage = useStorage();
  const [currentPackageData, setCurrentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );

  const { data: sessionData } = useSession();

  const isClient = useIsClient();
  const searchParams = useSearchParams();
  const eventId = searchParams?.get('eventId');

  const router = useRouter();
  const { propertyDetails } = props;
  const roomConfigurations = useBuildPackagesStore(
    (state) => state.roomConfigurations
  );
  const selectedTicketPrice = useBuildPackagesStore(state => state.selectedTicketPrice)


  const { priceRate, currency } = useCurrencyContext();
  const { isBundle,  } = useBuildPackageBundle();
  const cartId = useCheckoutStepsStore(state => state.cartId)

  const roomSelections = useSearchHotelStore((state) => state.roomSelections);

  const isRoomFilled = roomConfigurations.length === roomSelections.length;

  const totalPrice = useMemo(() => {
    if (!roomSelections.length) return 0;

    // @ts-ignore
    return roomSelections.reduce((acc, value) => acc + value.price, 0);
  }, [roomSelections]);

  const nextStep = useCallback(() => {
    const hotel = propertyDetails;
    const setSelectedDataToStorage = async () => {
      if (hotel) {
        const registrationTime = {
          checkIn: currentPackageData?.hotelStartDate,
          checkOut: currentPackageData?.hotelEndDate,
        };
        const data = {
          id: hotel.id,
          name: hotel.name,
          location: hotel.address,
          rating: hotel.rating,
          rooms: roomSelections.length,
          registrationTime,
          price: totalPrice,
          roomSelections,
          postalCode: hotel.address.postalCode,
          city: hotel.address.city,
          country: hotel.address.countryCode,
          phone: hotel?.phone,
          fax: hotel?.fax,
          image: hotel?.image,
          fullAddress: hotel?.fullAddress,
        };


        storage.setItem(SELECTED_HOTEL_STORAGE, JSON.stringify(data));

        const updatedPackageData = {
          ...currentPackageData,
          selected: { ...currentPackageData?.selected, hotel: true },
        };
        storage.setItem(PACKAGE_STORAGE, JSON.stringify(updatedPackageData));

        cartId && updateShoppingCart(cartId,
          {
            selectedHotelDetails: data,
            packageConfigDetails: updatedPackageData,
            currentStep: ShoppingCartStepEnum.HOTEL_SELECTION
          });

        const userIp = getCookie('user-ip')?.toString() || 'IP not found';
        const anonymousId =
          getCookie('anonymous-user-id')?.toString() ||
          'anonymous-user-id not found';

        const user = sessionData?.user;

        instance.server(routes.track, {
          body: JSON.stringify({
            userId: user?.id ? Number(user.id) : undefined,
            anonymousId: user ? undefined : anonymousId,
            trackedEvent: TrackedEvents.HOTEL_SELECTED,
            properties: JSON.stringify({
              ip: userIp,
              anonymousId,
              eventName: currentPackageData.eventName,
              eventDate: currentPackageData.eventDate,
              hotelEndDate: currentPackageData.hotelEndDate,
              hotelStartDate: currentPackageData.hotelStartDate,
              hotelId: hotel.id,
              hotelName: hotel.name,
              hotelLocation: hotel.address,
              hotelRating: hotel.rating,
              roomCount: roomSelections.length,
              registrationTime,
              hotelPrice: totalPrice,
              roomSelections,
              hotelPostalCode: hotel.address.postalCode,
              city: hotel.address.city,
              country: hotel.address.countryCode,
              phone: hotel?.phone,
              hotelFax: hotel?.fax,
              hotelAddress: hotel?.fullAddress,
            }),
          }),
          method: 'POST',
        });
      }
    };

    const mp = { ticket: currentPackageData.tickets, hotel: totalPrice };
    storage.setItem(MIN_PRICES, JSON.stringify(mp));


    setSelectedDataToStorage();
    const checkoutUrl = `${links.checkout}?eventId=${eventId}`;

    // if (currentPackageData.selected.tickets) {
    //   setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
    //   storage.setItem(SOCIAL_LOGIN_REDIRECT, checkoutUrl);
    //   return router.push(`${v2Links.searchTickets}?eventId=${eventId}`);
    // }

    useCheckoutStepsStore.setState({ hotelSelected: true });

    setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
    storage.setItem(SOCIAL_LOGIN_REDIRECT, checkoutUrl);
    router.push(checkoutUrl);
  }, [
    currentPackageData?.hotelEndDate,
    currentPackageData?.hotelStartDate,
    propertyDetails,
    totalPrice,
  ]);

  const { packageGuests } = useBuildPackageBundle();

  const isHotelIncluded = currentPackageData?.selected?.hotel;
  const bottomTotalPriceLabel = isBundle
    ? 'Include taxes, hotel & tickets'
    : 'Include taxes & hotel';

  if (!isClient) return null;





  const minHotelPrice = isBundle ? propertyDetails.minimumTotalPrice + selectedTicketPrice : propertyDetails.minimumTotalPrice
  const packagePrice = isBundle ? totalPrice + selectedTicketPrice : totalPrice;


  const priceValue = !isRoomFilled ? minHotelPrice : packagePrice



  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 w-full bg-gray-900">
      <div className="flex flex-col items-center justify-between gap-y-4 px-4 py-5 md:mx-auto md:max-w-[1400px] md:flex-row md:px-20">
        <div className="flex items-start justify-between gap-x-3 md:justify-start">
          <div>
            <h5 className="text-lg font-bold text-white">
              Estimated{' '}
              {formatCurrencyV2(
                priceValue / packageGuests,
                priceRate,
                currency,
                false,
                false
              )}{' '}
              per person
            </h5>

            <span className="text-sm font-medium text-gray-400">
              {formatCurrencyV2(priceValue, priceRate, currency, false, false)}{' '}
              in total. {bottomTotalPriceLabel}
            </span>
          </div>

          <Badge
            size="sm"
            variant="primary"
            className="flex-shrink-0 rounded-full"
          >
            {roomConfigurations.length === roomSelections.length ? (
              <>
                <Check
                  size="12"
                  pathProps={{ stroke: 'white' }}
                  className="flex-shrink-0"
                />

                <span className="ml-2.5 text-xs font-semibold text-white">
                  All  {pluralize('room', roomSelections.length)} selected{' '}
                </span>
              </>
            ) : (
              `${roomConfigurations.length - roomSelections.length} room(s) left`
            )}
          </Badge>
        </div>

        {!isRoomFilled ? (
          <Link
            className="w-full md:w-auto"
            href={`${links.checkout}?eventId=${eventId}`}
            onClick={() => {
              useCheckoutStepsStore.setState({ hotelSelected: false });
              setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
              storage.setItem(
                SOCIAL_LOGIN_REDIRECT,
                `${links.checkout}?eventId=${eventId}`
              );
            }}
          >
            <Button
              variant="ghost"
              className="w-full border border-gray-700 bg-transparent text-white md:w-auto"
            >
              {"I don't need a hotel"}
            </Button>
          </Link>
        ) : (
          <Button
            onClick={nextStep}
            disabled={!isRoomFilled}
            className={cn(
              !isRoomFilled && 'bg-gray-800 text-gray-400',
              'w-full md:w-max'
            )}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
}
