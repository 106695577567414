
export enum ShoppingCartStepEnum {
  BUNDLE_TYPE_SELECTION = 'BUNDLE_TYPE_SELECTION',
  TICKET_SELECTION = 'TICKET_SELECTION',
  HOTEL_SELECTION = 'HOTEL_SELECTION',
  CHECKOUT = 'CHECKOUT',
  COMPLETED = 'COMPLETED',
}

export interface CheckoutPayload {
  userId?: string | number | null;
  orderId: number;
  packageConfigDetails?: PackageDetails;
  selectedHotelDetails?: HotelDetails;
  selectedTicketDetails?: TicketDetails;
  currentStep: ShoppingCartStepEnum;
}


interface PackageDetails {
  tickets: number;
  hotelStartDate: string;
  hotelEndDate: string;
  roomConfigurations: RoomConfiguration[];
  selected: {
    hotel: boolean | undefined;
    tickets: boolean | undefined;
  };
  eventDate: string;
  eventName: string;
  location: LocationDetails;
  performers: Performer[];
  timeZone: string;
}

interface RoomConfiguration {
  adults: number;
  children: any[]; // Array can be further typed if required
  infants: number;
}

interface LocationDetails {
  id: string;
  name: string;
  country_name: string;
  city_name: string;
  latitude: number;
  longitude: number;
}

interface Performer {
  name: string;
  isPrimary: boolean;
}

interface HotelDetails {
  id: string;
  name: string;
  location: AddressDetails;
  rating: number;
  rooms: number;
  registrationTime: RegistrationTime;
  price: number;
  roomSelections: RoomSelection[];
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  image: string;
  fullAddress: string;
}

interface AddressDetails {
  line1: string;
  city: string;
  stateCode: string;
  stateName: string;
  postalCode: string;
  countryCode: string;
}

interface RegistrationTime {
  checkIn: string;
  checkOut: string;
}

interface RoomSelection {
  roomId: string;
  rateId: string;
  occupancy: string;
  price: number;
  description: string;
  name: string;
}

interface TicketDetails {
  price: number;
  wholesalePrice: number;
  retailPrice: number;
  tax: number;
  tax_signature: string;
  ticket_group_id: number;
  quantity: number;
  seat_order: string;
  ticket_group_signature: string;
  stadiumName: string;
  section: string;
  row: string;
  publicNotes: string;
  type: string;
}
