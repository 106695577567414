import { PropsWithChildren } from 'react';

import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { BaseProperty } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { Star01, Typography, typographyVariants } from '@v2/ui';
import { cn } from '@v2/utils';
import Image from 'next/image';
import { useMediaQuery } from 'usehooks-ts';
import { HOTEL_AMENETIES } from '../data';
type Props = {};

function Card({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <div
      className={cn(
        'w-full rounded-lg border-gray-200 bg-white md:border',
        className
      )}
    >
      {children}
    </div>
  );
}

function Content({
  children,
  className,
}: PropsWithChildren & { className?: string }) {
  return (
    <div
      className={cn(
        'flex w-full items-stretch border-b border-gray-200',
        className
      )}
    >
      {children}
    </div>
  );
}

export const DEFAULT_IMAGE_SIZE = 246;
function HotelImage({ image }: { image: string }) {
  return (
    <div
      className={`flex min-h-[210px] w-[130px] shrink-0 items-center justify-center bg-cover md:min-h-[246px] md:min-w-[246px]`}
    >
      <Image
        src={image}
        alt="hotel image"
        width="0"
        height="0"
        sizes="246"
        loading="lazy"
        className="h-full w-full rounded-bl-lg rounded-tl-lg object-cover object-center"
      />
    </div>
  );
}

function Overview(props: BaseProperty & { className?: string }) {
  const { name, distance, amenities, rating, price } = props;
  const { priceRate, currency } = useCurrencyContext();
  const matches = useMediaQuery('(min-width: 1024px)');

  const { packageGuests, isBundle, } = useBuildPackageBundle();
  const selectedTicketPrice = useBuildPackagesStore(state => state.selectedTicketPrice)



  const basePrice = !isBundle ? price : selectedTicketPrice + price;

  return (
    <div className={cn('flex w-full items-stretch')}>
      <div className="flex w-full flex-col items-stretch justify-between gap-y-6 p-4 md:flex-row md:p-6">
        <div className="flex flex-col md:justify-between">
          <div>
            <Typography
              size={matches ? 'dxs' : 'tmd'}
              className="mb-0.5 line-clamp-2 max-w-[22ch] truncate font-bold md:mb-2 md:line-clamp-1"
              style={{ whiteSpace: 'break-spaces' }}
            >
              {name}
            </Typography>

            <Typography
              size={matches ? 'tsm' : 'txs'}
              className="mb-6 font-medium text-gray-500"
            >
              {distance.toFixed(1)} mi from address
            </Typography>
            {matches && (
              <div className="mb-3 flex w-[90%] flex-wrap items-center justify-start gap-x-5">
                {HOTEL_AMENETIES.map((item) => {
                  if (
                    amenities.toLowerCase().includes(item.value.toLowerCase())
                  )
                    return (
                      <div
                        className="flex items-center gap-1.5"
                        key={item.label}
                      >
                        {item.icon}
                        <Typography
                          size="tsm"
                          className="font-medium text-gray-500"
                        >
                          {item.label}
                        </Typography>
                      </div>
                    );
                })}
              </div>
            )}
          </div>
          <div>
            <div className="flex w-max items-center gap-x-1.5 rounded-lg bg-gray-100 px-2 py-1 md:px-3">
              <span
                className={typographyVariants({
                  size: matches ? 'tmd' : 'txxs',
                  class: 'font-medium',
                })}
              >
                {rating}
              </span>{' '}
              <Star01
                fill="#667085"
                pathProps={{ stroke: '#667085' }}
                size={matches ? '16' : '12'}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col self-start text-start md:self-end md:text-right">
          <Typography size={matches ? 'tsm' : 'txs'} className="2 font-medium">
            Starting from
          </Typography>
          <Typography
            size={matches ? 'dmd' : 'dxs'}
            className="my-0.5 font-bold md:my-2"
          >
            {formatCurrencyV2(
              basePrice / packageGuests,
              priceRate,
              currency,
              false,
              false
            )}{' '}
          </Typography>

          <Typography
            size={matches ? 'txs' : 'txxs'}
            className="font-medium text-gray-500"
          >
            Per traveler {matches && <br />}
            <span className="text-nowrap">
              Includes {isBundle && 'tickets '} taxes & fees
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export { Card, Content, HotelImage, Overview };
