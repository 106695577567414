// 'use server';

import routes from '@config/routes';
import { serverFetcher } from '@instance';
import { CheckoutPayload } from './types';

interface AddCartResponse extends CheckoutPayload {
  createdAt: string;
  updatedAt: string;

  id: string;
}

export const addShoppingCart = async (
  vals: CheckoutPayload
): Promise<AddCartResponse> => {
  try {
    const body = JSON.stringify({
      ...vals,
    });

    const response = await serverFetcher(routes.shoppingCart, {
      method: 'POST',
      body,
      // headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (e) {
    console.log(e);
    throw e; // Re-throw the error after logging
  }
};

export const updateShoppingCart = async (
  cartId: string,
  payload: CheckoutPayload
): Promise<AddCartResponse> => {
  try {
    const body = JSON.stringify({
      ...payload,
    });

    const response = await serverFetcher(routes.shoppingCartId(cartId), {
      method: 'PATCH',
      body,
      // headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (e) {
    console.log(e);
    throw e; // Re-throw the error after logging
  }
};

export const getShoppingCartById = async (
  cartId: string
): Promise<AddCartResponse> => {
  try {
    const response = await serverFetcher(routes.shoppingCartId(cartId), {
      method: 'GET',
      // headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (e) {
    console.log(e);
    throw e; // Re-throw the error after logging
  }
};

