'use client';
import { ToggleGroup, ToggleGroupItem, Typography } from '@v2/ui';

import { buildPackageInitValuesV2 } from '@config/buildPackage';
import { TrackedEvents } from '@config/index';
import routes from '@config/routes';
import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';
import { useBetterMediaQuery } from '@hooks/use-ssr-media-query';
import instance from '@instance';
import { LoggedInUserProps } from '@interfaces/auth';
import invalidatePackageData from '@utils/cache-invalidate';
import { v2Links } from '@utils/navigation/links';
import { getCookie } from 'cookies-next';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';

import { ILocationResult } from '@components/searchCity';
import { PACKAGE_STORAGE } from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { LeagueEvent } from '@interfaces/APITravel';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { addShoppingCart } from '@v2/action/shopping-cart/shopping-cart-action';
import { ShoppingCartStepEnum } from '@v2/action/shopping-cart/types';

export type PackageType = 'packages' | 'tickets';

interface BuyPackageContentProps {
  event: LeagueEvent;
  user: LoggedInUserProps | null;
  hotelLocation: ILocationResult | undefined;
}

export function BuyPackageContent({
  user,
  hotelLocation,
  event,
}: BuyPackageContentProps) {
  useScrollToTopOnMount();
  const [selected, setSelected] = React.useState<PackageType | null>(null);
  const storage = useStorage();
  const searchParams = useSearchParams();
  const eventId = searchParams?.get('eventId');
  const eventLink = searchParams?.get('eventLink');
  const router = useRouter();

  const initBuildPackage = async (selectedPackageType: PackageType) => {
    const values = buildPackageInitValuesV2(
      event,
      hotelLocation,
      selectedPackageType
    );

    useBuildPackagesStore.setState({
      ...values,
      isBundle: selectedPackageType === 'packages',
    });

    storage.setItem(PACKAGE_STORAGE, JSON.stringify(values));

    addShoppingCart({ packageConfigDetails: values, currentStep: ShoppingCartStepEnum.BUNDLE_TYPE_SELECTION }).then(res => {
      useCheckoutStepsStore.getState().setCartId(res.id);
    })
  };

  const handleClick = (selected: PackageType) => {
    const anonymousId =
      getCookie('anonymous-user-id')?.toString() ||
      'anonymous-user-id not found';

    instance.server(routes.track, {
      body: JSON.stringify({
        userId: user?.id ? Number(user.id) : undefined,
        anonymousId: user ? undefined : anonymousId,
        trackedEvent: TrackedEvents.BUNDLE_TYPE,
        properties: JSON.stringify({
          isBundle: selected === 'packages',
        }),
      }),
      method: 'POST',
    });

    invalidatePackageData();

    initBuildPackage(selected);
    const url = `${v2Links.searchTickets}?packageType=${selected}&eventId=${eventId}&eventLink=${eventLink}`;
    router.push(url);
    useCheckoutStepsStore.getState().clearAllState();
  };

  const isTablet = useBetterMediaQuery('(min-width: 768px)');

  return (
    <div className="flex w-full flex-col gap-y-12">
      <Typography
        size={isTablet ? 'dmd' : 'dxs'}
        className="text-center font-bold text-gray-800"
      >
        I’m interested in...{' '}
      </Typography>

      <ToggleGroup
        type="single"
        variant="outline"
        className="gap-3"
        value={selected || ''}
        onValueChange={(val: PackageType) => setSelected(val)}
      >
        <ToggleGroupItem
          disabled={!hotelLocation}
          value="packages"
          className="flex h-[210px] w-[274px] flex-col items-center gap-y-5 px-0"
          onClick={() => handleClick('packages')}
        >
          <Image
            src={'/icons/luggage.svg'}
            alt="travel packages"
            width={80}
            height={80}
          />
          <Typography
            size={isTablet ? 'txl' : 'tmd'}
            className="text-center font-bold text-gray-800"
          >
            Travel packages
          </Typography>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="tickets"
          className="flex h-[210px] w-[274px] flex-col items-center gap-y-5 px-0"
          onClick={() => handleClick('tickets')}
        >
          <Image
            src={'/icons/plane-tickets.svg'}
            alt="Only tickets"
            width={80}
            height={80}
          />
          <Typography
            size={isTablet ? 'txl' : 'tmd'}
            className="text-center font-bold text-gray-800"
          >
            Only tickets
          </Typography>
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
}
