import { RoomConfiguration } from '@components/RoomConfigurator';
import type { Room } from '@interfaces/expedia/property';
import { RoomWithRate } from './RoomsList';

export function sortRoomsByOrder(rooms: Room[], selectedSortingOption: string) {
  const order = selectedSortingOption === 'price-low-high' ? 1 : -1;

  // Flatten the room and rate structure into an array of { room, rate } pairs
  const rateList = rooms.reduce((acc: RoomWithRate[], room) => {
    const ratesWithRoom = room.rates
      // .filter((val) => val.occupancyPricing?.length)
      .map((rate) => {
        return {
          room,
          rate,
          price: parseFloat(rate.occupancyPricing[0]?.total.value), // Assumes the first occupancy pricing is the relevant one
        };
      });
    return acc.concat(ratesWithRoom);
  }, []);

  // Sort the rate list by price
  rateList.sort((a, b) => order * (a.price - b.price));

  return rateList;
}

export function sortRoomsByRoomType(rooms: Room[], sortingOption: string) {
  if (!sortingOption) return rooms;
  // Split the sortingOption string into an array of filter options
  const filterOptions = sortingOption.split(',');

  const filteredListRoom =
    rooms?.filter((r) =>
      filterOptions.some((roomFeature) => {
        if (roomFeature.includes('Smoking')) {
          return r.amenities.includes(roomFeature);
        }

        return (
          r.rates?.some((rate) => {
            return rate.bedgroups?.some((bedgroup) => {
              return bedgroup.description
                .toLowerCase()
                .replaceAll(' ', '')
                .replaceAll('-', '')
                .includes(roomFeature.trim()); // Trim roomFeature to remove whitespace
            });
          }) ||
          r.name
            .toLowerCase()
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .includes(roomFeature.trim()) // Trim roomFeature to remove whitespace
        );
      })
    ) ?? null;

  return filteredListRoom;
}

export function buildOccupancies(roomConfigurations: RoomConfiguration[]) {
  const occupancies: string[] = [];

  roomConfigurations.forEach((roomConfiguration) => {
    let occupancy = `${roomConfiguration.adults}`;

    if (roomConfiguration.children.length) {
      occupancy += '-';

      roomConfiguration.children.forEach((age) => {
        occupancy += age;
        occupancy += ',';
      });

      occupancy = occupancy.substr(0, occupancy.length - 1);
    }
    occupancies.push(occupancy);
  });

  return occupancies;
}
