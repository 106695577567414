'use client';

import { roomFilters } from '@config/featureFilter';
import type { PropertyDetails, Rate, Room } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useSearchHotelStore } from '@store/search-hotel-store';
import {
  AlertCircleRed,
  AlertToast,
  AlertTriangle,
  Check,
  ChevronDown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from '@v2/ui';
import pluralize from 'pluralize';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { RoomCard } from '../RoomCard';
import { PlaceHolderIcon } from './icon';

import { useMediaQuery } from 'usehooks-ts';
import {
  buildOccupancies,
  sortRoomsByOrder,
  sortRoomsByRoomType,
} from './util';

type Props = {
  propertyDetails: PropertyDetails;
};

const roomsSortingOptions = [
  { name: 'Price (low to high)', value: 'price-low-high' },
  { name: 'Price (high to low)', value: 'price-high-low' },
];

export type RoomWithRate = {
  price: number;
  rate: Rate;
  room: Room;
};

export function RoomsList(props: Props) {
  const { rooms, minimumPricePerOccupancy } = props.propertyDetails;

  const roomsFilter = useState('');
  const sortByFilter = useState('price-low-high');
  const isTablet = useMediaQuery('(min-width: 768px)');

  const [roomsFilterValue] = roomsFilter;
  const [sortByValue] = sortByFilter;

  const list: RoomWithRate[] = useMemo(() => {
    if (!rooms.length) return [];

    const availableRooms = rooms?.filter((room) => room?.rates?.length > 0);

    return sortRoomsByOrder(
      sortRoomsByRoomType(availableRooms, roomsFilterValue),
      sortByValue
    );
  }, [rooms, sortByValue, roomsFilterValue]);

  const roomSelections = useSearchHotelStore((state) => state.roomSelections);

  const setRoomSelections = useSearchHotelStore(
    (state) => state.setHotelRoomSelection
  );

  const roomConfigurations = useBuildPackagesStore(
    (state) => state.roomConfigurations
  );

  const selectableOccupancies = useSearchHotelStore(
    (state) => state.selectableOccupancies
  );

  useEffect(() => {
    const occupancies = buildOccupancies(roomConfigurations);
    useSearchHotelStore.setState({ selectableOccupancies: [...occupancies] });
  }, [roomConfigurations]);

  /**
   * Handles the selection of a room.
   *
   * @param {Room} room - The selected room object.
   * @param {Rate} rate - The rate for the selected room.
   * @param {number} ratePrice - The price for the selected rate.
   * @param {any} occupancy - The occupancy for the selected room.
   * @param {number} modifier - A modifier value.
   */
  function onSelectRoom(room, rate, ratePrice, occupancy, modifier) {
    // Check if modifier is positive
    if (modifier > 0) {
      const selectedRateQty = roomSelections.filter(
        (s) => s.rateId === rate.id
      ).length;
      // Check if the selected rate quantity exceeds available rooms
      if (selectedRateQty >= rate.availableRooms) {
        // Display error toast if available rooms are exceeded
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Error"
              content={`We are sorry, but the property only has ${rate.availableRooms} rooms available at this rate.`}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      } else if (roomSelections.length >= 8) {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Error"
              content={
                'We are sorry, but you cannot book more than 8 rooms at once.'
              }
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      } else if (roomSelections.length >= roomConfigurations.length) {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title={`You only configured ${roomConfigurations.length} rooms.`}
              content={`If you want to book more rooms, please go back and change your room configuration.`}
              icon={<AlertCircleRed />}
            />
          ),
          { position: 'top-right' }
        );
      } else if (!selectableOccupancies.includes(occupancy)) {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="You already selected a room for this occupancy."
              content={`If you need more rooms of this type, please go back and change your room configuration.`}
              icon={<AlertTriangle />}
            />
          ),
          { position: 'top-right' }
        );
      } else {
        // Add room selection
        setRoomSelections({
          roomId: room.id,
          rateId: rate.id,
          occupancy,
          price: ratePrice,
          description: rate?.bedgroups[0]?.description,
          name: room?.name,
        });
        // Remove selected occupancy from selectableOccupancies
        const index = selectableOccupancies.indexOf(occupancy);
        if (index > -1) {
          const newOccupancies = [...selectableOccupancies];
          newOccupancies.splice(index, 1);
          useSearchHotelStore.setState({
            selectableOccupancies: [...newOccupancies],
          });
        }
      }

      return;
    }
  }

  const totalRooms = list.length;

  return (
    <div className="mt-12" id="roomsSection">
      <div className="mb-6 flex flex-col items-start justify-between gap-y-6 md:flex-row md:items-center">
        <div>
          <Typography
            size={isTablet ? 'dxs' : 'txl'}
            className="mb-2 font-bold"
          >
            Choose your room(s)
          </Typography>
          <Typography
            size={isTablet ? 'tmd' : 'tsm'}
            className="font-medium text-gray-500"
          >
            {totalRooms} {pluralize('option', totalRooms)} available
          </Typography>
        </div>

        <Filters roomsFilter={roomsFilter} sortByFilter={sortByFilter} />
      </div>

      {!list.length && <EmptyContent />}

      {list.length > 0 && (
        <div className="flex flex-wrap gap-3">
          {list.map((item) => {
            const rate = item.rate;
            const room = item.room;
            return (
              <RoomCard
                key={room.id + rate.id}
                {...room}
                room={room}
                rate={rate}
                minimumPricePerOccupancy={minimumPricePerOccupancy}
                onSelectRoom={onSelectRoom}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

function EmptyContent() {
  return (
    <div className="flex flex-col items-center justify-center bg-white py-12">
      <PlaceHolderIcon />
      <h3 className="mb-2 mt-6 text-xl font-bold text-gray-500">
        No rooms available
      </h3>
      <p className="text-md font-medium text-gray-500">
        Review your filters or try a different search
      </p>
    </div>
  );
}

type FiltersProps = {
  roomsFilter: [
    string | null,
    React.Dispatch<React.SetStateAction<string | null>>,
  ];
  sortByFilter: [string, React.Dispatch<React.SetStateAction<string>>];
};

function Filters(props: FiltersProps) {
  const [roomsFilter, setRoomsFilter] = props.roomsFilter;
  const [sortByFilter, setSortByFilter] = props.sortByFilter;

  const sortBy = roomsSortingOptions.find(
    (item) => item.value === sortByFilter
  )!.name!;

  const roomsLabel =
    (roomsFilter &&
      roomFilters.find((item) => item.value === roomsFilter)?.label) ||
    'All rooms';

  return (
    <div className="flex gap-x-6">
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="inline-flex items-center">
          <Typography size="tmd" className="font-semibold">
            {roomsLabel}
          </Typography>

          <ChevronDown className="ml-2.5" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[250px]" align="start">
          <DropdownMenuItem
            className="justify-between"
            onSelect={() => setRoomsFilter(null)}
          >
            All rooms
            {!roomsFilter && <Check />}
          </DropdownMenuItem>

          {roomFilters.map((item) => (
            <DropdownMenuItem
              key={item.value}
              className="justify-between"
              onSelect={() => setRoomsFilter(item.value)}
            >
              {item.label}
              {item.value === roomsFilter && <Check />}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className="inline-flex items-center">
          <Typography size="tmd" className="font-semibold">
            Sort by {sortBy}
          </Typography>

          <ChevronDown className="ml-2.5" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[250px]" align="start">
          {roomsSortingOptions.map((item) => (
            <DropdownMenuItem
              textValue={item.value}
              key={item.value}
              className="justify-between"
              onSelect={() => setSortByFilter(item.value)}
            >
              {item.name}
              {item.value === sortByFilter && <Check />}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
